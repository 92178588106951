body {
  --c_main: #48a1a5;
  --c_main_trans_half: #48a1a688;
  --c_main_trans: #48a1a6CC;
  --c_dark: #036caa;
  --c_light: #dddfe0;
  --c_light_trans_half: #dddfe088;
  --c_red_light: #dd5656;
  --c_red_main: #e24d32;
  --c_red_dark: #d23c3c;
  --c_red_dark_trans: #d23c3cCC;
  --c_yellow: #cfb63e;
  --c_yellow_trans: #cfb63eCC;
  --c_green_rich: #3ecf3e;
  --c_green_rich_trans: #3ecf3eCC;
  --c_text_dark: #333333;
  --c_text_main: #3e4757;
  --c_text_grey: #9299a2;
  --c_text_light: #F6F7F7;
  --c_background: #FFFFFF;
  --c_input: #fff;
  --c_input_text: #3e4757;
}

body[theme=dark] {
  --c_main: #48a1a6;
  --c_main_trans_half: #48a1a688;
  --c_main_trans: #48a1a6CC;
  --c_dark: #036caa;
  --c_light: #909090;
  --c_light_trans_half: #dddfe088;
  --c_red_light: #dd5656;
  --c_red_main: #e24d32;
  --c_red_dark: #d23c3c;
  --c_red_dark_trans: #d23c3cCC;
  --c_yellow: #cfb63e;
  --c_yellow_trans: #cfb63eCC;
  --c_green_rich: #3ecf3e;
  --c_green_rich_trans: #3ecf3eCC;
  --c_text_dark: #CCCCCC;
  --c_text_main: #EDEFF2;
  --c_text_grey: #F6F7F7;
  --c_text_light: #F6F7F7;
  --c_background: #191a1c;
  --c_input: #2b2a32;
  --c_input_text: #ffffff;
}

ul#user-menu-box {
  position: absolute;
  top: 13px;
  right: -3px;
  background: #fff;
  color: var(--c_main);
  border: 1px solid var(--c_main);
  border-radius: 3px;
  margin: 3px 0 0 0;
  padding: 0;
  display: none;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1.1px;
  text-align: right;
  cursor: pointer;
  z-index: 10;
  opacity: 0;
  transition: opacity .1s ease;
  list-style: none;
}

ul#user-menu-box.visible {
  opacity: 1;
  transition: opacity .1s ease;
}

ul#user-menu-box li {
  padding: 0;
  border-top: 1px solid var(--c_light);
}

ul#user-menu-box li a {
  padding: 20px;
  text-decoration: none;
  display: block;
}

ul#user-menu-box li:first-child {
  border-top: 0;
}

div#user-info-wrap {
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 20;
  display: none;
  width: 100%;
  background-color: rgba(0,0,0,0);
  transition: background-color .2s ease;
}

div#user-info-wrap div#user-info-wrap-inner {
  height: 100%;
  width: 100%;
  max-width: 1278px;
  background-color: #fff;
  overflow-y: auto;
  overflow-x: hidden;
}

@media only screen and (min-width: 451px) {
  div#user-info-wrap div#user-info-wrap-inner {
    width: 350px;
    transform: translateX(350px);
    transition: transform .2s ease;
    will-change: transform;
    margin-right: 0;
    margin-left: auto;
  }
}

div#user-info-wrap.visible {
  display: block;
  background-color: rgba(0,0,0,.4);
  transition: background-color .2s ease;
}

@media only screen and (min-width: 451px) {
  div#user-info-wrap.visible div#user-info-wrap-inner {
    transform: translateX(0);
  }
}

div#user-info-wrap div#user-info-box {
  margin: 0;
  padding: 24px 18px 0;
  color: var(--c_main);
  position: relative;
}

div#user-info-wrap div#user-info-box button#user-info-close {
  display: block;
  width: 100%;
  padding: 0 6px;
  font-size: 14px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 1.1px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  border-width: 0;
  font-family: inherit;
  background: none;
  color: inherit;
}

div#user-info-wrap div#user-info-box div#user-info-header {
  color: var(--c_text_main);
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

div#user-info-wrap div#user-info-box div#user-info-header div#user-info-logo {
  height: 80px;
  width: 80px;
  background-image: url("./img/logo160.png");
  background-size: cover;
  margin-left: auto;
  margin-right: auto;
}

div#user-info-wrap div#user-info-box div#user-info-header div#user-info-title {
  margin-top: 12px;
  font-weight: 900;
  font-size: 42px;
  line-height: 1.4em;
}

div#user-info-wrap div#user-info-box div#user-info-header div#user-info-subtitle {
  margin-top: -6px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5em;
  letter-spacing: -0.2px;
}

div#user-info-wrap div#user-info-box div#user-info-content {
  margin: 12px 0 66px 0;
  text-align: center;
}

div#user-info-wrap div#user-info-box div#user-info-footer {
  text-align: center;
  max-width: 1078px;
  padding: 0 12px;
}

div#user-info-wrap div#user-info-box div#user-info-footer div#user-info-footer-logo {
  display: inline-block;
  margin: 12px 0;
  width: 206px;
  height: 59px;
  background: url("../main/img/footer_logo.png") center no-repeat;
  vertical-align: middle;
}

div#user-info-wrap div#user-info-box div#user-info-footer ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 12px 0;
  padding: 0;
}

div#user-info-wrap div#user-info-box div#user-info-footer ul li {
  display: inline-block;
  margin: 0;
  padding: 12px 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5em;
}

div#user-info-wrap div#user-info-box div#user-info-footer ul li a {
  color: var(--c_main);
  text-decoration: none;
}

