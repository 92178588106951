body {
  --c_main: #48a1a5;
  --c_main_trans_half: #48a1a688;
  --c_main_trans: #48a1a6CC;
  --c_dark: #036caa;
  --c_light: #dddfe0;
  --c_light_trans_half: #dddfe088;
  --c_red_light: #dd5656;
  --c_red_main: #e24d32;
  --c_red_dark: #d23c3c;
  --c_red_dark_trans: #d23c3cCC;
  --c_yellow: #cfb63e;
  --c_yellow_trans: #cfb63eCC;
  --c_green_rich: #3ecf3e;
  --c_green_rich_trans: #3ecf3eCC;
  --c_text_dark: #333333;
  --c_text_main: #3e4757;
  --c_text_grey: #9299a2;
  --c_text_light: #F6F7F7;
  --c_background: #FFFFFF;
  --c_input: #fff;
  --c_input_text: #3e4757;
}

body[theme=dark] {
  --c_main: #48a1a6;
  --c_main_trans_half: #48a1a688;
  --c_main_trans: #48a1a6CC;
  --c_dark: #036caa;
  --c_light: #909090;
  --c_light_trans_half: #dddfe088;
  --c_red_light: #dd5656;
  --c_red_main: #e24d32;
  --c_red_dark: #d23c3c;
  --c_red_dark_trans: #d23c3cCC;
  --c_yellow: #cfb63e;
  --c_yellow_trans: #cfb63eCC;
  --c_green_rich: #3ecf3e;
  --c_green_rich_trans: #3ecf3eCC;
  --c_text_dark: #CCCCCC;
  --c_text_main: #EDEFF2;
  --c_text_grey: #F6F7F7;
  --c_text_light: #F6F7F7;
  --c_background: #191a1c;
  --c_input: #2b2a32;
  --c_input_text: #ffffff;
}

div.input-radio-group.small-height {
  padding: 6px 12px 12px;
}

div.input-radio-group.small-height div.label,
div.input-radio-group.small-height div.label-collapsed {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: var(--c_text_grey);
}

div.input-radio-group.small-height div.label-collapsed {
  display: none;
  background: url("./img/input-select-arrow.svg") no-repeat right center;
  background-size: auto 42%;
}

div.input-radio-group.small-height.collapsed {
  padding: 6px 12px;
  cursor: pointer;
}

div.input-radio-group.small-height.collapsed div.label-collapsed {
  display: block;
}

div.input-radio-group.small-height.collapsed div.label,
div.input-radio-group.small-height.collapsed div.input-radio {
  display: none;
}

div.input-radio-group.small-height:focus {
  outline: none;
}

div.input-radio-group.small-height div.input-radio {
  padding: 6px 0;
}

div.input-radio-group.small-height div.input-radio label {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: var(--c_text_grey);
  cursor: pointer;
}

div.input-radio-group.small-height div.input-radio label:before {
  content: "";
  color: var(--c_main);
  display: inline-block;
  width: 10px;
  height: 10px;
  border: solid 1px var(--c_main);
  border-radius: 6px;
  vertical-align: text-top;
  text-align: center;
  margin-right: 6px;
  font-size: 30px;
  line-height: 10px;
  text-indent: -0.4px;
}

div.input-radio-group.small-height div.input-radio input {
  width: 0;
  height: 0;
  opacity: 0;
}

div.input-radio-group.small-height div.input-radio input:checked+label {
  color: var(--c_text_main);
}

div.input-radio-group.small-height div.input-radio input:checked+label:before {
  content: "•";
}

