body {
  --c_main: #48a1a5;
  --c_main_trans_half: #48a1a688;
  --c_main_trans: #48a1a6CC;
  --c_dark: #036caa;
  --c_light: #dddfe0;
  --c_light_trans_half: #dddfe088;
  --c_red_light: #dd5656;
  --c_red_main: #e24d32;
  --c_red_dark: #d23c3c;
  --c_red_dark_trans: #d23c3cCC;
  --c_yellow: #cfb63e;
  --c_yellow_trans: #cfb63eCC;
  --c_green_rich: #3ecf3e;
  --c_green_rich_trans: #3ecf3eCC;
  --c_text_dark: #333333;
  --c_text_main: #3e4757;
  --c_text_grey: #9299a2;
  --c_text_light: #F6F7F7;
  --c_background: #FFFFFF;
  --c_input: #fff;
  --c_input_text: #3e4757;
}

body[theme=dark] {
  --c_main: #48a1a6;
  --c_main_trans_half: #48a1a688;
  --c_main_trans: #48a1a6CC;
  --c_dark: #036caa;
  --c_light: #909090;
  --c_light_trans_half: #dddfe088;
  --c_red_light: #dd5656;
  --c_red_main: #e24d32;
  --c_red_dark: #d23c3c;
  --c_red_dark_trans: #d23c3cCC;
  --c_yellow: #cfb63e;
  --c_yellow_trans: #cfb63eCC;
  --c_green_rich: #3ecf3e;
  --c_green_rich_trans: #3ecf3eCC;
  --c_text_dark: #CCCCCC;
  --c_text_main: #EDEFF2;
  --c_text_grey: #F6F7F7;
  --c_text_light: #F6F7F7;
  --c_background: #191a1c;
  --c_input: #2b2a32;
  --c_input_text: #ffffff;
}

div.input-select {
  display: inline-block;
  vertical-align: middle;
  width: 246px;
  position: relative;
  font-size: 16px;
  font-family: "Raleway",serif;
  font-weight: 500;
  -webkit-appearance: none;
  background-color: var(--c_input);
  overflow: visible;
  text-align: left;
  box-sizing: content-box;
}

div.input-select label {
  position: absolute;
  top: 18px;
  left: 0;
  right: 0;
  width: auto;
  padding-right: 50px;
  padding-left: 18px;
  box-sizing: border-box;
  color: var(--c_main);
  transition-property: font-size,top,opacity;
  -moz-transition-timing-function: ease;
  transition-duration: .1s;
  pointer-events: none;
  white-space: nowrap;
  overflow: hidden;
  line-height: 1.3em;
  opacity: 1;
}

div.input-select select {
  padding-top: 26px;
  padding-bottom: 9px;
  padding-left: 18px;
  border: 1px solid var(--c_main);
  border-radius: 3px;
  height: 60px;
  width: 100%;
  font-size: 17px;
  box-sizing: border-box;
  outline: none;
  background: rgba(0,0,0,0);
  line-height: 1.3em;
}

div.input-select.focused label,
div.input-select.expanded label,
div.input-select.non-empty label {
  color: var(--c_text_grey);
  font-size: 13px;
  top: 8px;
}

div.input-select.with-errors {
  margin-bottom: 20px;
}

div.input-select.with-errors ul.errorlist {
  position: absolute;
  bottom: -30px;
  left: 0;
  height: 30px;
  box-sizing: border-box;
  padding: 3px 0 0 0;
  margin: 0;
  font-size: 13px;
  color: var(--c_red_light);
  line-height: 1.3;
  list-style-type: none;
}

div.input-select.with-errors ul.errorlist li {
  margin: 0;
  padding: 0;
}

div.input-select.with-errors ul.errorlist li:not(:first-child) {
  display: none;
}

div.input-select div.input-select-arrow {
  position: absolute;
  top: 18px;
  right: 18px;
  height: 22px;
  width: 22px;
  background-image: url("./img/input-select-arrow.svg");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  background-color: inherit;
  cursor: pointer;
  transform: scaleY(1);
  transition: transform ease .1s;
}

div.input-select.expanded div.input-select-arrow {
  transform: scaleY(-1);
}

div.input-select div.input-select-box {
  height: 60px;
  overflow: visible;
  color: var(--c_main);
  background-color: inherit;
}

div.input-select div.input-select-box input {
  display: block;
  height: 60px;
  border: 1px solid var(--c_main);
  border-radius: 3px;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  padding: 30px 50px 9px 18px;
  color: var(--c_main);
  font-size: 16px;
  font-weight: 500;
  font-family: "Raleway",serif;
  outline-width: 0;
  background-color: inherit;
}

div.input-select ul.input-select-list {
  display: block;
  margin: 0;
  padding: 0;
  border: 1px solid var(--c_main);
  border-top-width: 0;
  border-radius: 0 0 3px 3px;
  position: absolute;
  box-sizing: border-box;
  bottom: 1px;
  width: 100%;
  overflow-y: auto;
  max-height: 200px;
  background-color: inherit;
  z-index: 0;
  transform: translate(0, 50%) scaleY(0);
  transition: transform linear .1s;
}

div.input-select ul.input-select-list li {
  display: block;
  width: 100%;
  box-sizing: border-box;
  border: 0 solid var(--c_light);
  border-top-width: 1px;
  vertical-align: middle;
  padding: 20px 6px 20px 18px;
  cursor: pointer;
  overflow: hidden;
}

div.input-select.content-list ul.input-select-list {
  box-sizing: content-box;
  left: -1px;
}

div.input-select.expanded div.input-select-box input {
  border-bottom-color: var(--c_light);
  border-radius: 3px 3px 0 0;
}

div.input-select.expanded ul.input-select-list {
  display: block;
  z-index: 10;
  transform: translate(0, 100%) scaleY(1);
  transition: transform linear .1s;
}

div.input-select.free-input-select div.input-select-box input {
  cursor: text;
}

div.input-select.search-list-select.non-empty:not(.expanded) div.input-select-arrow {
  background-image: url("./img/input-select-cross.svg");
}

div.input-select.small-height {
  width: 100%;
}

div.input-select.small-height label {
  top: 16px;
  padding-left: 12px;
  font-size: 12px;
  color: var(--c_text_grey);
}

div.input-select.small-height select {
  padding-top: 26px;
  padding-bottom: 6px;
  padding-left: 12px;
  font-size: 12px;
  color: var(--c_text_main);
  height: 48px;
}

div.input-select.small-height div.input-select-box {
  height: 48px;
}

div.input-select.small-height div.input-select-box input {
  height: 48px;
  padding: 26px 36px 6px 12px;
  font-size: 12px;
}

div.input-select.small-height.focused label,
div.input-select.small-height.expanded label,
div.input-select.small-height.non-empty label {
  font-size: 12px;
  top: 6px;
}

div.input-select.small-height div.input-select-arrow {
  position: absolute;
  top: 16px;
  right: 16px;
  height: 16px;
  width: 16px;
}

div.input-select.small-height ul.input-select-list {
  max-height: 150px;
  font-size: 12px;
}

div.input-select.small-height ul.input-select-list li {
  padding: 17px 3px 17px 18px;
}

div.input-select.search-list-extended {
  background-image: url("./img/input-select-cross.svg");
}

div.input-select.search-list-extended div.input-select-arrow {
  display: none;
}

div.input-select.search-list-extended label {
  padding-left: 66px;
  padding-right: 0;
  top: 22px;
}

div.input-select.search-list-extended div.input-select-box {
  box-shadow: inset 0 0 0 1px var(--c_main);
  border-radius: 3px;
}

div.input-select.search-list-extended div.input-select-box input {
  padding: 20px 18px 20px 66px;
  border: none;
  background: url("./img/search.svg") no-repeat 30px center;
  cursor: text;
  color: var(--c_text_dark);
  font-weight: 600;
}

div.input-select.search-list-extended.focused label,
div.input-select.search-list-extended.expanded label,
div.input-select.search-list-extended.non-empty label {
  color: var(--c_main);
  font-size: 16px;
  top: 22px;
}

div.input-select.search-list-extended.non-empty label {
  opacity: 0;
}

div.input-select.search-list-extended ul.input-select-list li {
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
  height: 60px;
  line-height: 60px;
  white-space: nowrap;
}

div.input-select.search-list-extended ul.input-select-list li div.image {
  width: 42px;
  height: 42px;
  margin: 9px 12px;
  border-radius: 21px;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
}

div.input-select.search-list-extended ul.input-select-list li div.image img {
  min-width: 42px;
  min-height: 42px;
  width: 1px;
  height: auto;
  vertical-align: top;
}

div.input-select.search-list-extended ul.input-select-list li div.text {
  display: inline-block;
  vertical-align: top;
  font-weight: 600;
  color: var(--c_text_dark);
}

div.input-select.select-horizontal {
  width: auto;
}

div.input-select.select-horizontal ul.input-select-list {
  border: 1px solid var(--c_main);
  border-radius: 3px;
  position: static;
  top: 0;
  width: 100%;
  max-height: auto;
  background-color: inherit;
  z-index: 0;
  transform: none;
  transition: transform linear .1s;
}

div.input-select.select-horizontal ul.input-select-list li {
  display: inline-block;
  width: auto;
  box-sizing: border-box;
  border: 0 solid var(--c_light);
  border-left-width: 1px;
  vertical-align: middle;
  padding: 20px 18px 20px 18px;
  cursor: pointer;
  overflow: hidden;
}

div.input-select.select-horizontal ul.input-select-list li:first-child {
  border-left-width: 0;
}

div.input-select.select-horizontal ul.input-select-list li.selected {
  box-shadow: inset 0 5px 10px 0 var(--c_light);
  background: var(--c_light);
}

div.input-select.search-select-multiple div.input-select-box {
  border: 1px solid var(--c_main);
  border-radius: 3px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 50px 6px 0;
  display: flex;
  flex-wrap: wrap;
  min-height: 60px;
  height: auto;
}

div.input-select.search-select-multiple div.input-select-box span.selected-item-text {
  cursor: pointer;
  margin: 28px 0 -16px 12px;
  position: relative;
  display: block;
  padding: 6px 36px 6px 12px;
  background: var(--c_main);
  color: #fff;
  border-radius: 6px;
  font-size: .7em;
  height: 13px;
  flex-grow: 0;
  flex-shrink: 0;
}

div.input-select.search-select-multiple div.input-select-box span.selected-item-text:after {
  content: "x";
  display: inline;
  position: absolute;
  right: 7px;
  top: 1px;
  font-size: 1.2em;
  border-left: 1px solid var(--c_light);
  padding: 2px 0 2px 7px;
  margin: 2px;
  text-transform: uppercase;
}

div.input-select.search-select-multiple div.input-select-box input {
  cursor: text;
  display: inline-block;
  border: 0;
  padding: 30px 0 9px 18px;
  width: auto;
  height: 58px;
  flex-grow: 1;
}

div.input-select.search-select-multiple div.input-select-box ul.input-select-list {
  margin-left: -1px;
}

