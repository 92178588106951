@import "~normalize.css";

@import "../fonts/raleway.css";

body {
  --c_main: #48a1a5;
  --c_main_trans_half: #48a1a688;
  --c_main_trans: #48a1a6CC;
  --c_dark: #036caa;
  --c_light: #dddfe0;
  --c_light_trans_half: #dddfe088;
  --c_red_light: #dd5656;
  --c_red_main: #e24d32;
  --c_red_dark: #d23c3c;
  --c_red_dark_trans: #d23c3cCC;
  --c_yellow: #cfb63e;
  --c_yellow_trans: #cfb63eCC;
  --c_green_rich: #3ecf3e;
  --c_green_rich_trans: #3ecf3eCC;
  --c_text_dark: #333333;
  --c_text_main: #3e4757;
  --c_text_grey: #9299a2;
  --c_text_light: #F6F7F7;
  --c_background: #FFFFFF;
  --c_input: #fff;
  --c_input_text: #3e4757;
}

body[theme=dark] {
  --c_main: #48a1a6;
  --c_main_trans_half: #48a1a688;
  --c_main_trans: #48a1a6CC;
  --c_dark: #036caa;
  --c_light: #909090;
  --c_light_trans_half: #dddfe088;
  --c_red_light: #dd5656;
  --c_red_main: #e24d32;
  --c_red_dark: #d23c3c;
  --c_red_dark_trans: #d23c3cCC;
  --c_yellow: #cfb63e;
  --c_yellow_trans: #cfb63eCC;
  --c_green_rich: #3ecf3e;
  --c_green_rich_trans: #3ecf3eCC;
  --c_text_dark: #CCCCCC;
  --c_text_main: #EDEFF2;
  --c_text_grey: #F6F7F7;
  --c_text_light: #F6F7F7;
  --c_background: #191a1c;
  --c_input: #2b2a32;
  --c_input_text: #ffffff;
}

strong {
  font-weight: 900;
}

hr {
  border-width: 0;
  border-top: 1px solid var(--c_light);
  margin: 24px 0;
}

body {
  font-family: "Raleway",sans-serif;
  font-display: block;
  min-width: 320px;
  color: var(--c_text_dark);
  background-color: var(--c_background);
}

a {
  color: var(--c_main);
}

#page-wrap {
  width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

#page-wrap>header#header-small {
  color: var(--c_main);
  border-bottom: 1px solid var(--c_light);
}

#page-wrap>header#header-small div#menu-top-wrap {
  background-image: url(./img/smol_logo.png);
  background-position: 0px 20px;
  background-size: 55px 55px;
  background-repeat: no-repeat;
}

body[theme=dark] #page-wrap>header#header-small div#menu-top-wrap {
  background-image: url(./img/smol_logo_dark.png);
}

@media only screen and (max-width: 900px) {
  #page-wrap>header#header-small div#menu-top-wrap {
    background-position: center;
    background-size: 24px;
  }
}

#page-wrap>header#header-small div#menu-top-wrap div#menu-top {
  position: relative;
  padding-left: 118px;
  max-width: 1178px;
  margin: 0 auto 0 0;
}

@media only screen and (max-width: 900px) {
  #page-wrap>header#header-small div#menu-top-wrap div#menu-top {
    height: 82px;
    margin: 0 auto;
    padding-left: 18px;
  }
}

#page-wrap>header#header-small div#menu-top-wrap div#menu-top a.home-link {
  display: inline-block;
  height: 96px;
  width: 100px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

@media only screen and (max-width: 900px) {
  #page-wrap>header#header-small div#menu-top-wrap div#menu-top a.home-link {
    width: 164px;
    height: 82px;
    left: 50%;
    margin-left: -87px;
    background-position: 63px 22px;
  }
}

#page-wrap>header#header-small div#menu-top-wrap div#menu-top nav#header-menu a {
  color: var(--c_main);
}

#page-wrap>header#header-small div#menu-top-wrap div#menu-top nav#header-menu a.active {
  color: var(--c_text_main);
  font-weight: 900;
}

#page-wrap>header#header-small div#menu-top-wrap div#menu-top button#mobile-menu {
  margin-top: 4px;
  background: url(./img/hamburger-g.svg) no-repeat 0 24px;
}

#page-wrap>header#header-small div#menu-top-wrap div#menu-top button#mobile-login {
  margin-top: 4px;
  background: url(./img/enter-g.svg) no-repeat 24px 24px;
}

#page-wrap>header#header-small div#menu-top-wrap div#menu-top button#mobile-user-info {
  margin-top: 4px;
  background: url(./img/exit-g.svg) no-repeat 24px 24px;
}

#page-wrap>header#header-big {
  width: 100%;
  font-family: "Raleway",sans-serif;
  background-color: #136a8a;
  background-image: linear-gradient(292deg, #136a8a, #267871);
  background-size: cover;
  color: #fff;
}

#page-wrap>header#header-big div#menu-top {
  z-index: 15;
}

#page-wrap>header#header-big div#menu-top a.home-link {
  display: none;
}

#page-wrap>header#header-big div#info-top {
  z-index: 10;
}

#page-wrap>header#header-big div#header-mask {
  z-index: 0;
  background-image: url(./img/background.jpg);
  background-size: cover;
  mix-blend-mode: soft-light;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

#page-wrap>header#header-big div#header-mask2 {
  z-index: 1;
  background-image: linear-gradient(292deg, #136a8a, #267871);
  opacity: .5;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

#page-wrap>header {
  position: relative;
}

#page-wrap>header div#menu-top-wrap {
  width: 100%;
  max-width: 1278px;
  margin: 0 auto;
}

#page-wrap>header div#menu-top-wrap div#menu-top {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  max-width: 1078px;
  box-sizing: border-box;
  padding: 0 18px;
  height: 96px;
}

#page-wrap>header div#menu-top-wrap div#menu-top nav#header-menu {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1.1px;
  display: inline-block;
  overflow: hidden;
}

@media only screen and (max-width: 900px) {
  #page-wrap>header div#menu-top-wrap div#menu-top nav#header-menu {
    display: none;
  }
}

#page-wrap>header div#menu-top-wrap div#menu-top nav#header-menu a {
  padding-top: 37px;
  margin-right: 25px;
  text-decoration: none;
  color: #fff;
  display: inline-block;
  height: 59px;
}

#page-wrap>header div#menu-top-wrap div#menu-top button#mobile-menu {
  padding: 24px 24px 24px 0;
  display: none;
  height: 26px;
  width: 26px;
  background: url(./img/hamburger.svg) no-repeat 0 24px;
  cursor: pointer;
  border-width: 0;
  font-family: inherit;
  box-sizing: content-box;
  color: inherit;
}

@media only screen and (max-width: 900px) {
  #page-wrap>header div#menu-top-wrap div#menu-top button#mobile-menu {
    display: block;
  }
}

#page-wrap>header div#menu-top-wrap div#menu-top button#header-login {
  padding: 0 0 6px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1.1px;
  text-align: right;
  display: inline-block;
  cursor: pointer;
  border-width: 0;
  font-family: inherit;
  box-sizing: content-box;
  background: none;
  color: inherit;
}

@media only screen and (max-width: 900px) {
  #page-wrap>header div#menu-top-wrap div#menu-top button#header-login {
    display: none;
  }
}

#page-wrap>header div#menu-top-wrap div#menu-top button#header-login a {
  text-decoration: none;
  color: #fff;
}

#page-wrap>header div#menu-top-wrap div#menu-top button#mobile-login {
  padding: 24px 0 24px 24px;
  display: none;
  height: 26px;
  width: 26px;
  background: url(./img/enter.svg) no-repeat 24px 24px;
  cursor: pointer;
  border-width: 0;
  font: inherit;
  box-sizing: content-box;
}

@media only screen and (max-width: 900px) {
  #page-wrap>header div#menu-top-wrap div#menu-top button#mobile-login {
    display: block;
  }
}

#page-wrap>header div#menu-top-wrap div#menu-top button#header-user-info {
  margin: 0;
  padding: 0 0 6px 0;
  font-size: 14px;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1.1px;
  text-align: right;
  display: inline-block;
  cursor: pointer;
  border-width: 0;
  font-family: inherit;
  color: inherit;
  box-sizing: content-box;
  background: none;
  transition: outline 0s linear .1s;
}

@media only screen and (max-width: 900px) {
  #page-wrap>header div#menu-top-wrap div#menu-top button#header-user-info {
    display: none;
  }
}

#page-wrap>header div#menu-top-wrap div#menu-top button#header-user-info:hover {
  outline-width: 0;
}

#page-wrap>header div#menu-top-wrap div#menu-top button#header-user-info a {
  text-decoration: none;
  color: #fff;
}

#page-wrap>header div#menu-top-wrap div#menu-top button#mobile-user-info {
  padding: 24px 0 24px 24px;
  display: none;
  height: 26px;
  width: 26px;
  background: url(./img/exit.svg) no-repeat 24px 24px;
  cursor: pointer;
  border-width: 0;
  font: inherit;
  box-sizing: content-box;
}

@media only screen and (max-width: 900px) {
  #page-wrap>header div#menu-top-wrap div#menu-top button#mobile-user-info {
    display: block;
  }
}

#page-wrap>header div#info-top {
  position: relative;
  clear: both;
  background: rgba(0,0,0,0);
  display: flex;
  justify-content: flex-start;
  margin: 0 auto;
  width: 100%;
  max-width: 1078px;
  box-sizing: border-box;
  padding: 0 18px 60px;
}

@media only screen and (max-width: 900px) {
  #page-wrap>header div#info-top {
    padding-top: 0;
    display: block;
    text-align: center;
  }
}

#page-wrap>header div#info-top div#header-logo {
  box-sizing: border-box;
  margin-top: 10px;
  padding: 10px 50px 10px;
  text-align: center;
  background: url(./img/main_logo_glow.png) no-repeat;
  background-size: contain;
  width: 300px;
  height: 300px;
}

@media only screen and (max-width: 900px) {
  #page-wrap>header div#info-top div#header-logo {
    width: 50%;
    height: auto;
    padding: 0;
    text-align: center;
    max-width: 300px;
    max-height: 300px;
  }
}

#page-wrap>header div#info-top div#header-text {
  box-sizing: border-box;
  display: inline-block;
  width: 605px;
  padding: 13px 10px 0;
}

@media only screen and (max-width: 900px) {
  #page-wrap>header div#info-top div#header-text {
    width: 100%;
    padding: 0 18px;
    text-align: center;
  }
}

#page-wrap>header div#info-top div#header-text h1 {
  font-weight: 900;
  padding: 0;
  margin: 0;
  font-size: 42px;
  line-height: 1.4em;
}

@media only screen and (max-width: 450px) {
  #page-wrap>header div#info-top div#header-text h1 {
    font-size: 34px;
  }
}

#page-wrap>header div#info-top div#header-text h2 {
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5em;
}

#page-wrap>header div#info-top div#header-text div#header-article {
  padding: 16px 0 36px 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5em;
}

@media only screen and (max-width: 1099px) {
  #page-wrap>header div#info-top div#header-text div#header-buttons a:nth-child(2) {
    display: none;
  }
}

#page-wrap>#submenu {
  border-bottom: 1px solid var(--c_light);
}

@media only screen and (max-width: 900px) {
  #page-wrap>#submenu {
    display: none;
  }
}

#page-wrap>#submenu #submenu-wrap {
  margin: 0 auto;
  width: 100%;
  max-width: 1078px;
}

#page-wrap>#submenu #submenu-wrap a {
  color: var(--c_main);
  font-size: 14px;
  font-weight: 700;
  line-height: 1.1;
  letter-spacing: 1.1px;
  padding: 24px 0 24px 18px;
  text-decoration: none;
  text-transform: uppercase;
  display: inline-block;
}

#page-wrap>#submenu #submenu-wrap a.active {
  font-weight: 900;
  line-height: 1;
  color: var(--c_text_main);
}

#page-wrap>#content-wrap {
  margin: 30px auto;
  max-width: 1078px;
  width: 100%;
  padding: 0 18px;
  box-sizing: border-box;
}

@media only screen and (min-width: 901px) {
  #page-wrap>#content-wrap {
    margin-top: 60px;
  }
}

#page-wrap>footer {
  clear: both;
  margin: 142px auto 0;
  border-top: 1px solid var(--c_light);
  text-align: center;
  padding: 40px 18px;
}

@media only screen and (max-width: 900px) {
  #page-wrap>footer {
    margin-top: 78px;
    padding-top: 24px;
  }
}

#page-wrap>footer div#dark-theme-button {
  height: 30px;
  width: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(./img/dark_theme_button_dark.png);
}

body[theme=dark] #page-wrap>footer div#dark-theme-button {
  background-image: url(./img/dark_theme_button.png);
}

#page-wrap>footer div#footer-logo {
  display: inline-block;
  margin: 12px 24px;
  width: 206px;
  height: 59px;
  background-image: url(./img/footer_logo.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  vertical-align: middle;
}

body[theme=dark] #page-wrap>footer div#footer-logo {
  background-image: url(./img/footer_logo_dark.png);
}

#page-wrap>footer ul {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin: 12px 24px;
  padding: 0;
}

@media only screen and (max-width: 900px) {
  #page-wrap>footer ul {
    flex-direction: column;
    display: flex;
  }
}

#page-wrap>footer ul li {
  display: inline-block;
  margin: 0;
  padding: 12px 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5em;
}

#page-wrap>footer ul li a {
  color: var(--c_main);
  text-decoration: none;
}

article {
  position: relative;
}

#quill-text {
  min-height: 100px;
}

article,
#quill-text {
  width: 100%;
  color: var(--c_text_dark);
  font-family: "Raleway",serif;
  font-size: 16px;
  font-weight: 500;
}

article h1,
#quill-text h1 {
  font-size: 30px;
  line-height: 1.4;
  font-weight: 700;
  margin: 36px 0 24px 0;
  color: var(--c_text_dark);
  text-align: left;
}

@media only screen and (max-width: 900px) {
  article h1,
  #quill-text h1 {
    text-align: center;
  }
}

article div.subheader,
#quill-text div.subheader {
  margin: 24px 0 36px 0;
  font-size: 16px;
  line-height: 18px;
  font-weight: 900;
  color: var(--c_text_dark);
  text-align: left;
}

@media only screen and (max-width: 900px) {
  article div.subheader,
  #quill-text div.subheader {
    text-align: center;
  }
}

article h2,
#quill-text h2 {
  margin: 48px 0 24px 0;
  font-size: 24px;
  line-height: 30px;
  font-weight: 800;
  color: var(--c_text_dark);
  text-align: left;
}

@media only screen and (max-width: 900px) {
  article h2,
  #quill-text h2 {
    text-align: center;
  }
}

article h3,
#quill-text h3 {
  margin: 42px 0 12px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 900;
  text-align: left;
}

article p,
article ul,
article ol,
#quill-text p,
#quill-text ul,
#quill-text ol {
  margin: 24px 0;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: .2px;
}

article a,
#quill-text a {
  color: var(--c_main);
  font-style: inherit;
  font-size: inherit;
  font-weight: inherit;
}

article aside,
#quill-text aside {
  font-size: 14px;
  font-weight: 400;
}

article pre,
article code,
#quill-text pre,
#quill-text code {
  font-size: 14px;
  font-family: monospace;
  background-color: #f0f0f0;
  color: var(--c_text_dark);
}

article strong,
#quill-text strong {
  font-weight: 800;
}

section {
  width: 100%;
  position: relative;
  margin: 36px 0;
}

section>h1 {
  margin: 12px;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.4;
  color: var(--c_text_main);
}

@media only screen and (min-width: 901px) {
  section>h1.font-big {
    font-size: 42px;
  }
}

section p.subheader {
  padding: 0;
  margin: 16px 0 51px;
  text-align: center;
}

section p.subheader a {
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.1;
  color: var(--c_text_grey);
}

section>h2 {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.1;
  text-align: center;
  color: var(--c_text_dark);
  margin: 24px 0 36px;
}

section a.header-side-link,
section a.header-top-link,
section a.header-side-link-2,
section a.header-back-link,
article a.header-side-link,
article a.header-top-link,
article a.header-side-link-2,
article a.header-back-link {
  text-align: center;
  margin: 4px 0 30px 0;
  display: block;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.1;
  letter-spacing: 1.1px;
  color: var(--c_main);
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
}

@media only screen and (min-width: 901px) {
  section a.header-side-link,
  section a.header-top-link,
  section a.header-side-link-2,
  section a.header-back-link,
  article a.header-side-link,
  article a.header-top-link,
  article a.header-side-link-2,
  article a.header-back-link {
    text-align: right;
  }
}

@media only screen and (min-width: 901px) {
  section a.header-side-link,
  article a.header-side-link {
    position: absolute;
    top: 12px;
    right: 6px;
  }

  section a.header-side-link-2,
  article a.header-side-link-2 {
    position: absolute;
    top: 64px;
    right: 6px;
  }

  section a.header-top-link,
  article a.header-top-link {
    margin: 0 0 -36px 0;
  }

  section a.header-back-link,
  article a.header-back-link {
    position: absolute;
    top: 12px;
    left: 6px;
  }

  section a.header-back-link:before,
  article a.header-back-link:before {
    content: "";
    display: inline-block;
    background: url("./img/back.svg") right bottom no-repeat;
    background-size: 13px 11px;
    width: 20px;
    height: 13px;
    transform: scaleX(-1);
  }
}

.middle-helper {
  line-height: 100%;
  height: 100%;
  width: 0;
  vertical-align: middle;
  display: inline-block;
  visibility: hidden;
}

.dashed-border {
  border-radius: 4px;
  border: dashed 3px;
  border-image-source: url("./img/border_t.png");
  border-image-slice: 3;
  border-image-repeat: round;
}

a.right-arrow {
  padding-right: 23px;
  background: url("./img/watch_on_site.svg") no-repeat right bottom;
  background-size: 17px 15px;
}

