@import '../util/vars'
@import '../forms/index'

div#login-wrap
  height: 100%
  width: 100%
  position: absolute
  top: 0
  z-index: 20
  display: none

  div#login-wrap-inner
    height: 100%
    margin: 0 auto
    width: 100%
    background-image: linear-gradient(292deg, rgba(19, 106, 138, 0.7), rgba(38, 120, 113, 0.7))
    @include respond-to(handhelds)
      overflow-y: auto
      overflow-x: hidden

  div#login-box
    height: 100%
    margin: 0
    width: 100%
    padding: 0
    color: white
    border-width: 0 45px
    border-style: solid
    border-color: white
    box-sizing: content-box
    position: relative
    left: -45px
    @include respond-to(handhelds)
      height: auto
      min-height: 100%

    >div
      margin: 0 auto
      width: 100%
      max-width: $content_width
      box-sizing: border-box
      padding-left: $global_padding
      padding-right: $global_padding

    div#login-cancel
      text-align: right
      text-transform: uppercase
      font-weight: 600
      font-size: 14px
      letter-spacing: 1px
      padding-top: 36px
      padding-bottom: 3px
      cursor: pointer
      @include respond-to(handhelds)
        text-align: center

    div#login-content
      top: 50%
      position: relative
      margin-top: -230px
      @include respond-to(handhelds)
        top: 0
        margin-top: 36px

      div#login-box-header
        text-align: center
        font-size: 42px
        font-weight: 900
        line-height: 1.4
        padding-top: 12px
        padding-bottom: 28px
        position: relative
        left: -10px
        @include respond-to(handhelds)
          left: -5px

        span#login-box-logo img
            position: relative
            bottom: 4px
            height: 80px
            width: auto
            vertical-align: middle
            background: url(../main/img/main_logo_glow.png) no-repeat
            background-size: cover

            @include respond-to(handhelds)
              height: 54px
              bottom: 0

        span#login-box-title
          @include respond-to(handhelds)
            font-size: 30px

      div#login-errors-wrap
        height: 20px

        div#login-errors
          text-align: center
          height: 20px
          line-height: 20px
          font-weight: 600
          transition: color .1s linear

          &.highlighted
            color: var(--c_red_dark)

      div#login-form
        padding-top: 12px
        padding-bottom: 48px
        display: flex
        justify-content: space-between
        @include respond-to(handhelds)
          flex-direction: column
          align-items: center

        >div, >button
          width: 32%
          box-sizing: border-box
          height: 64px
          margin: 18px 0 0
          @include respond-to(handhelds)
            width: 100%
            max-width: 500px

        >button
          @include respond-to(handhelds)
            margin-top: 42px

      div#login-comment
        font-size: 16px
        font-weight: normal
        line-height: 1.5
        text-align: center
        color: var(--c_text_light)

        a
          color: var(--c_text_light)
