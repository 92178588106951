/*100*/
@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 100;
    src: local('Raleway Thin'),
    local('Raleway-Thin'),
    url('raleway/otf/Raleway-Thin.otf') format('opentype'),
    url('raleway/ttf/Raleway-Thin.ttf') format('truetype');
}
@font-face {
    font-family: 'Raleway';
    font-style: italic;
    font-weight: 100;
    src: local('Raleway Thin'),
    local('Raleway-Thin'),
    url('raleway/otf/Raleway-Thin-Italic.otf') format('opentype'),
    url('raleway/ttf/Raleway-Thin-Italic.ttf') format('truetype');
}
/*200*/
@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 200;
    src: local('Raleway ExtraLight'),
    local('Raleway-ExtraLight'),
    url('raleway/otf/Raleway-ExtraLight.otf') format('opentype'),
    url('raleway/ttf/Raleway-ExtraLight.ttf') format('truetype');
}
@font-face {
    font-family: 'Raleway';
    font-style: italic;
    font-weight: 200;
    src: local('Raleway ExtraLight'),
    local('Raleway-ExtraLight'),
    url('raleway/otf/Raleway-ExtraLight-Italic.otf') format('opentype'),
    url('raleway/ttf/Raleway-ExtraLight-Italic.ttf') format('truetype');
}
/*300*/
@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 300;
    src: local('Raleway Light'),
    local('Raleway-Light'),
    url('raleway/otf/Raleway-Light.otf') format('opentype'),
    url('raleway/ttf/Raleway-Light.ttf') format('truetype');
}
@font-face {
    font-family: 'Raleway';
    font-style: italic;
    font-weight: 300;
    src: local('Raleway Light'),
    local('Raleway-Light'),
    url('raleway/otf/Raleway-Light-Italic.otf') format('opentype'),
    url('raleway/ttf/Raleway-Light-Italic.ttf') format('truetype');
}
/*400*/
@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    src: local('Raleway'),
    local('Raleway-Regular'),
    url('raleway/otf/Raleway-Regular.otf') format('opentype'),
    url('raleway/ttf/Raleway-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Raleway';
    font-style: italic;
    font-weight: 400;
    src: local('Raleway'),
    local('Raleway-Regular'),
    url('raleway/otf/Raleway-Regular-Italic.otf') format('opentype'),
    url('raleway/ttf/Raleway-Regular-Italic.ttf') format('truetype');
}
/*500*/
@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    src: local('Raleway Medium'),
    local('Raleway-Medium'),
    url('raleway/otf/Raleway-Medium.otf') format('opentype'),
    url('raleway/ttf/Raleway-Medium.ttf') format('truetype');
}
@font-face {
    font-family: 'Raleway';
    font-style: italic;
    font-weight: 500;
    src: local('Raleway Medium'),
    local('Raleway-Medium'),
    url('raleway/otf/Raleway-Medium-Italic.otf') format('opentype'),
    url('raleway/ttf/Raleway-Medium-Italic.ttf') format('truetype');
}
/*600*/
@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    src: local('Raleway SemiBold'),
    local('Raleway-SemiBold'),
    url('raleway/otf/Raleway-SemiBold.otf') format('opentype'),
    url('raleway/ttf/Raleway-SemiBold.ttf') format('truetype');
}
@font-face {
    font-family: 'Raleway';
    font-style: italic;
    font-weight: 600;
    src: local('Raleway SemiBold'),
    local('Raleway-SemiBold'),
    url('raleway/otf/Raleway-SemiBold-Italic.otf') format('opentype'),
    url('raleway/ttf/Raleway-SemiBold-Italic.ttf') format('truetype');
}
/*700*/
@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    src: local('Raleway Bold'),
    local('Raleway-Bold'),
    url('raleway/otf/Raleway-Bold.otf') format('opentype'),
    url('raleway/ttf/Raleway-Bold.ttf') format('truetype');
}
@font-face {
    font-family: 'Raleway';
    font-style: italic;
    font-weight: 700;
    src: local('Raleway Bold'),
    local('Raleway-Bold'),
    url('raleway/otf/Raleway-Bold-Italic.otf') format('opentype'),
    url('raleway/ttf/Raleway-Bold-Italic.ttf') format('truetype');
}
/*800*/
@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 800;
    src: local('Raleway ExtraBold'),
    local('Raleway-ExtraBold'),
    url('raleway/otf/Raleway-ExtraBold.otf') format('opentype'),
    url('raleway/ttf/Raleway-ExtraBold.ttf') format('truetype');
}
@font-face {
    font-family: 'Raleway';
    font-style: italic;
    font-weight: 800;
    src: local('Raleway ExtraBold'),
    local('Raleway-ExtraBold'),
    url('raleway/otf/Raleway-ExtraBold-Italic.otf') format('opentype'),
    url('raleway/ttf/Raleway-ExtraBold-Italic.ttf') format('truetype');
}
/*900*/
@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 900;
    src: local('Raleway Black'),
    local('Raleway-Black'),
    url('raleway/otf/Raleway-Black.otf') format('opentype'),
    url('raleway/ttf/Raleway-Black.ttf') format('truetype');
}
@font-face {
    font-family: 'Raleway';
    font-style: italic;
    font-weight: 900;
    src: local('Raleway Black'),
    local('Raleway-Black'),
    url('raleway/otf/Raleway-Black-Italic.otf') format('opentype'),
    url('raleway/ttf/Raleway-Black-Italic.ttf') format('truetype');
}
