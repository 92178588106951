body {
  --c_main: #48a1a5;
  --c_main_trans_half: #48a1a688;
  --c_main_trans: #48a1a6CC;
  --c_dark: #036caa;
  --c_light: #dddfe0;
  --c_light_trans_half: #dddfe088;
  --c_red_light: #dd5656;
  --c_red_main: #e24d32;
  --c_red_dark: #d23c3c;
  --c_red_dark_trans: #d23c3cCC;
  --c_yellow: #cfb63e;
  --c_yellow_trans: #cfb63eCC;
  --c_green_rich: #3ecf3e;
  --c_green_rich_trans: #3ecf3eCC;
  --c_text_dark: #333333;
  --c_text_main: #3e4757;
  --c_text_grey: #9299a2;
  --c_text_light: #F6F7F7;
  --c_background: #FFFFFF;
  --c_input: #fff;
  --c_input_text: #3e4757;
}

body[theme=dark] {
  --c_main: #48a1a6;
  --c_main_trans_half: #48a1a688;
  --c_main_trans: #48a1a6CC;
  --c_dark: #036caa;
  --c_light: #909090;
  --c_light_trans_half: #dddfe088;
  --c_red_light: #dd5656;
  --c_red_main: #e24d32;
  --c_red_dark: #d23c3c;
  --c_red_dark_trans: #d23c3cCC;
  --c_yellow: #cfb63e;
  --c_yellow_trans: #cfb63eCC;
  --c_green_rich: #3ecf3e;
  --c_green_rich_trans: #3ecf3eCC;
  --c_text_dark: #CCCCCC;
  --c_text_main: #EDEFF2;
  --c_text_grey: #F6F7F7;
  --c_text_light: #F6F7F7;
  --c_background: #191a1c;
  --c_input: #2b2a32;
  --c_input_text: #ffffff;
}

a[class^=button-],
button[class^=button-],
input[type=submit],
input[type=button] {
  text-align: center;
  min-width: 250px;
  height: 60px;
  line-height: 24px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  padding: 17px 18px 19px 18px;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  outline: none;
  vertical-align: top;
  box-sizing: border-box;
  cursor: pointer;
}

a[class^=button-].button-w,
button[class^=button-].button-w,
input[type=submit].button-w,
input[type=button].button-w {
  background-color: #fff;
  border: 1px solid var(--c_main);
  color: var(--c_dark);
}

a[class^=button-].button-t,
button[class^=button-].button-t,
input[type=submit].button-t,
input[type=button].button-t {
  background-color: rgba(0,0,0,0);
  border: 0 solid var(--c_main);
  color: #fff;
}

a[class^=button-].button-t-b,
button[class^=button-].button-t-b,
input[type=submit].button-t-b,
input[type=button].button-t-b {
  background-color: rgba(0,0,0,0);
  border: solid 2px #fff;
  color: #fff;
}

a[class^=button-].button-g,
button[class^=button-].button-g,
input[type=submit].button-g,
input[type=button].button-g {
  background-image: linear-gradient(283deg, #136a8a, #267871);
  border: 0 solid var(--c_main);
  color: #fff;
}

a[class^=button-].button-r,
button[class^=button-].button-r,
input[type=submit].button-r,
input[type=button].button-r {
  background-image: linear-gradient(283deg, #B32919, #EC5151);
  border: 0 solid var(--c_main);
  color: #fff;
}

