@import "../util/vars"

a[class^="button-"], button[class^="button-"], input[type='submit'], input[type='button']
  text-align: center
  min-width: 250px
  height: 60px
  line-height: 24px
  border-radius: 4px
  font-size: 14px
  font-weight: 700
  padding: 17px 18px 19px 18px
  text-transform: uppercase
  text-decoration: none
  display: inline-block
  outline: none
  vertical-align: top
  box-sizing: border-box
  cursor: pointer

  &.button-w
    background-color: white
    border: 1px solid var(--c_main)
    color: var(--c_dark)

  &.button-t
    background-color: transparent
    border: 0 solid var(--c_main)
    color: white

  &.button-t-b
    background-color: transparent
    border: solid 2px #ffffff
    color: white

  &.button-g
    background-image: linear-gradient(283deg, #136a8a, #267871)
    border: 0 solid var(--c_main)
    color: white

  &.button-r
    background-image: linear-gradient(283deg, #B32919, #EC5151)
    border: 0 solid var(--c_main)
    color: white
