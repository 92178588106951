@import 'input_text'
@import 'select'
@import 'radio'
@import 'button'
@import 'core/static/util/vars'

div.input-hidden
  display: none

.edit-form-flex
  display: flex
  justify-content: space-between
  align-items: center
  @include respond-to(handhelds)
    flex-direction: column

  .input-text, .input-select, .input-quill
    margin: 9px
    flex-grow: 1
    width: auto
    text-align: left
    @include respond-to(handhelds)
      width: 100%
      max-width: 400px

.success-message
  text-align: center
  width: fit-content
  border: 1px solid var(--c_light)
  border-radius: 5px
  margin-left: auto
  margin-right: auto
  padding: 10px
  padding-left: 15px
  padding-right: 15px
  color: var(--c_main)
  