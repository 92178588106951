@import '../util/vars'

div#menu-wrap
  height: 100%
  position: fixed
  top: 0
  z-index: 20
  display: none
  width: 100%
  background-color: rgba(0,0,0,0.0)
  transition: background-color .2s ease

  div#menu-wrap-inner
    height: 100%
    width: 100%
    max-width: $site_width
    background-color: var(--c_background)
    overflow-y: auto
    overflow-x: hidden
    @include respond-except(mini-handhelds)
      width: 350px
      transform: translateX(-350px)
      transition: transform .2s ease
      will-change: transform

  &.visible
    display: block
    background-color: rgba(0,0,0,0.4)
    transition: background-color .2s ease
    div#menu-wrap-inner
      @include respond-except(mini-handhelds)
        transform: translateX(0)

  div#menu-box
    margin: 0
    padding: 24px $global_padding 0
    color: var(--c_main)
    position: relative

    button#menu-close
      display: block
      width: 100%
      padding: 0 6px
      font-size: 14px
      font-weight: 700
      line-height: 26px
      letter-spacing: 1.1px
      text-transform: uppercase
      text-align: center
      cursor: pointer

      border-width: 0
      font-family: inherit
      box-sizing: border-box
      background: none
      color: inherit

    div#menu-content
      margin: 12px 0 66px 0

      nav div
        padding: 12px
        font-size: 14px
        font-weight: 700
        line-height: 1.1em
        letter-spacing: 1.1px
        text-transform: uppercase

        &.sub-item
           padding: 6px 0 0 6px

        a
          text-decoration: none
          color: var(--c_main)

        &.active>a
          color: var(--c_text_main)

    div#menu-footer
      border-top: 1px solid var(--c_light)
      text-align: center
      max-width: $content_width
      padding: 36px 12px


      div#menu-footer-logo
        display: inline-block
        margin: 12px 0
        width: 206px
        height: 59px
        background: url('../main/img/footer_logo.png') center no-repeat
        background-size: contain
        vertical-align: middle

      ul
        display: flex
        flex-direction: column
        align-items: center
        margin: 12px 0
        padding: 0

        li
          display: inline-block
          margin: 0
          padding: 12px 0
          font-size: 16px
          font-weight: 500
          line-height: 1.5em

          a
            color: var(--c_main)
            text-decoration: none