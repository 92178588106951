@import "../util/vars"

div.input-text
  display: inline-block
  width: 500px
  border: 1px solid var(--c_main)
  border-radius: 3px
  position: relative
  cursor: text
  box-sizing: content-box

  label
    position: absolute
    top: 18px
    left: 18px
    color: var(--c_main)
    font-size: 17px
    font-weight: 500
    cursor: text
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none
    transition-property: font-size, top
    -moz-transition-timing-function: ease
    transition-duration: .1s

  textarea
    resize: none
    padding-right: 18px
    font-family: 'Raleway', serif

  input, textarea
    padding-top: 30px
    padding-bottom: 9px
    padding-left: 18px
    border: none
    line-height: 21px
    width: 100%
    font-weight: 500
    font-size: 17px
    box-sizing: border-box
    outline: none
    background: transparent
    font-family: 'Raleway', serif

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active
      transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s

  &.not-labeled
    input, textarea
      padding-top: 9px
    label
      display: none

  &.center-text
    input, textarea
      padding-right: 18px
      text-align: center

  &.full-width
    width: 100%

  &.with-errors
    margin-bottom: 20px

    ul.errorlist
      position: absolute
      bottom: -30px
      left: 0
      height: 30px
      box-sizing: border-box
      padding: 3px 0 0 0
      margin: 0
      font-size: 13px
      color: var(--c_red_light)
      line-height: 1.3
      list-style-type: none

      li
        margin: 0
        padding: 0

      li:not(:first-child)
        display: none

  input:required:not(:invalid)+label,
  input:focus+label,
  textarea:required:not(:invalid)+label,
  textarea:focus+label,
  &.focused label,
  &.always-filled label,
  &.non-empty label
    font-size: 13px
    top: 8px
    transition-property: font-size, top
    -moz-transition-timing-function: ease
    transition-duration: .1s
    opacity: 0.5

  &.white-colored
    border: solid 2px white
    input, label, textarea
      color: white


div.input-text.small-height
  width: 100%

  label
    top: 16px
    left: 12px
    font-size: 12px
    font-weight: 500
    line-height: 1.33
    color: var(--c_text_grey)

  input, textarea
    padding-top: 26px
    padding-bottom: 6px
    padding-left: 12px
    font-size: 12px
    font-weight: 500
    line-height: 1.33
    color: var(--c_text_main)

  &.not-labeled
    input, textarea
      padding-top: 6px
    label
      display: none

  &.center-text
    input, textarea
      padding-right: 12px
      text-align: center

  input:required:not(:invalid)+label,
  input:focus+label,
  textarea:required:not(:invalid)+label,
  textarea:focus+label,
  &.focused label,
  &.always-filled label,
  &.non-empty label
    font-size: 12px
    top: 6px
    transition-property: top
    -moz-transition-timing-function: ease
    transition-duration: .1s
    opacity: 1

div.input-text.with-clean-btn
  button.clean-btn
    background: none
    border: none
    bottom: 9px
    right: 5px
    font-size: 21px
    margin: 0 5px 0 20px
    color: var(--c_main)
    position: absolute
    padding: 6px 13px 9px
    cursor: pointer
    display: none

  input:required:not(:invalid),
  textarea:required:not(:invalid),
  &.always-filled,
  &.non-empty
    button.clean-btn
      display: block

div.input-text.full-wide
  width: 100%

div.input-text.half-wide
  width: auto
  flex-grow: 1
  @include respond-to(handhelds)
    width: 100%

  