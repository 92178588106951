body {
  --c_main: #48a1a5;
  --c_main_trans_half: #48a1a688;
  --c_main_trans: #48a1a6CC;
  --c_dark: #036caa;
  --c_light: #dddfe0;
  --c_light_trans_half: #dddfe088;
  --c_red_light: #dd5656;
  --c_red_main: #e24d32;
  --c_red_dark: #d23c3c;
  --c_red_dark_trans: #d23c3cCC;
  --c_yellow: #cfb63e;
  --c_yellow_trans: #cfb63eCC;
  --c_green_rich: #3ecf3e;
  --c_green_rich_trans: #3ecf3eCC;
  --c_text_dark: #333333;
  --c_text_main: #3e4757;
  --c_text_grey: #9299a2;
  --c_text_light: #F6F7F7;
  --c_background: #FFFFFF;
  --c_input: #fff;
  --c_input_text: #3e4757;
}

body[theme=dark] {
  --c_main: #48a1a6;
  --c_main_trans_half: #48a1a688;
  --c_main_trans: #48a1a6CC;
  --c_dark: #036caa;
  --c_light: #909090;
  --c_light_trans_half: #dddfe088;
  --c_red_light: #dd5656;
  --c_red_main: #e24d32;
  --c_red_dark: #d23c3c;
  --c_red_dark_trans: #d23c3cCC;
  --c_yellow: #cfb63e;
  --c_yellow_trans: #cfb63eCC;
  --c_green_rich: #3ecf3e;
  --c_green_rich_trans: #3ecf3eCC;
  --c_text_dark: #CCCCCC;
  --c_text_main: #EDEFF2;
  --c_text_grey: #F6F7F7;
  --c_text_light: #F6F7F7;
  --c_background: #191a1c;
  --c_input: #2b2a32;
  --c_input_text: #ffffff;
}

div.input-text {
  display: inline-block;
  width: 500px;
  border: 1px solid var(--c_main);
  border-radius: 3px;
  position: relative;
  cursor: text;
  box-sizing: content-box;
}

div.input-text label {
  position: absolute;
  top: 18px;
  left: 18px;
  color: var(--c_main);
  font-size: 17px;
  font-weight: 500;
  cursor: text;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition-property: font-size,top;
  -moz-transition-timing-function: ease;
  transition-duration: .1s;
}

div.input-text textarea {
  resize: none;
  padding-right: 18px;
  font-family: "Raleway",serif;
}

div.input-text input,
div.input-text textarea {
  padding-top: 30px;
  padding-bottom: 9px;
  padding-left: 18px;
  border: none;
  line-height: 21px;
  width: 100%;
  font-weight: 500;
  font-size: 17px;
  box-sizing: border-box;
  outline: none;
  background: rgba(0,0,0,0);
  font-family: "Raleway",serif;
}

div.input-text input:-webkit-autofill,
div.input-text input:-webkit-autofill:hover,
div.input-text input:-webkit-autofill:focus,
div.input-text input:-webkit-autofill:active,
div.input-text textarea:-webkit-autofill,
div.input-text textarea:-webkit-autofill:hover,
div.input-text textarea:-webkit-autofill:focus,
div.input-text textarea:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s,color 5000s ease-in-out 0s;
}

div.input-text.not-labeled input,
div.input-text.not-labeled textarea {
  padding-top: 9px;
}

div.input-text.not-labeled label {
  display: none;
}

div.input-text.center-text input,
div.input-text.center-text textarea {
  padding-right: 18px;
  text-align: center;
}

div.input-text.full-width {
  width: 100%;
}

div.input-text.with-errors {
  margin-bottom: 20px;
}

div.input-text.with-errors ul.errorlist {
  position: absolute;
  bottom: -30px;
  left: 0;
  height: 30px;
  box-sizing: border-box;
  padding: 3px 0 0 0;
  margin: 0;
  font-size: 13px;
  color: var(--c_red_light);
  line-height: 1.3;
  list-style-type: none;
}

div.input-text.with-errors ul.errorlist li {
  margin: 0;
  padding: 0;
}

div.input-text.with-errors ul.errorlist li:not(:first-child) {
  display: none;
}

div.input-text input:required:not(:invalid)+label,
div.input-text input:focus+label,
div.input-text textarea:required:not(:invalid)+label,
div.input-text textarea:focus+label,
div.input-text.focused label,
div.input-text.always-filled label,
div.input-text.non-empty label {
  font-size: 13px;
  top: 8px;
  transition-property: font-size,top;
  -moz-transition-timing-function: ease;
  transition-duration: .1s;
  opacity: .5;
}

div.input-text.white-colored {
  border: solid 2px #fff;
}

div.input-text.white-colored input,
div.input-text.white-colored label,
div.input-text.white-colored textarea {
  color: #fff;
}

div.input-text.small-height {
  width: 100%;
}

div.input-text.small-height label {
  top: 16px;
  left: 12px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: var(--c_text_grey);
}

div.input-text.small-height input,
div.input-text.small-height textarea {
  padding-top: 26px;
  padding-bottom: 6px;
  padding-left: 12px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: var(--c_text_main);
}

div.input-text.small-height.not-labeled input,
div.input-text.small-height.not-labeled textarea {
  padding-top: 6px;
}

div.input-text.small-height.not-labeled label {
  display: none;
}

div.input-text.small-height.center-text input,
div.input-text.small-height.center-text textarea {
  padding-right: 12px;
  text-align: center;
}

div.input-text.small-height input:required:not(:invalid)+label,
div.input-text.small-height input:focus+label,
div.input-text.small-height textarea:required:not(:invalid)+label,
div.input-text.small-height textarea:focus+label,
div.input-text.small-height.focused label,
div.input-text.small-height.always-filled label,
div.input-text.small-height.non-empty label {
  font-size: 12px;
  top: 6px;
  transition-property: top;
  -moz-transition-timing-function: ease;
  transition-duration: .1s;
  opacity: 1;
}

div.input-text.with-clean-btn button.clean-btn {
  background: none;
  border: none;
  bottom: 9px;
  right: 5px;
  font-size: 21px;
  margin: 0 5px 0 20px;
  color: var(--c_main);
  position: absolute;
  padding: 6px 13px 9px;
  cursor: pointer;
  display: none;
}

div.input-text.with-clean-btn input:required:not(:invalid) button.clean-btn,
div.input-text.with-clean-btn textarea:required:not(:invalid) button.clean-btn,
div.input-text.with-clean-btn.always-filled button.clean-btn,
div.input-text.with-clean-btn.non-empty button.clean-btn {
  display: block;
}

div.input-text.full-wide {
  width: 100%;
}

div.input-text.half-wide {
  width: auto;
  flex-grow: 1;
}

@media only screen and (max-width: 900px) {
  div.input-text.half-wide {
    width: 100%;
  }
}

