@import "~normalize.css"
@import "../fonts/raleway.css"
@import "../util/vars"

strong
  font-weight: 900

hr
  border-width: 0
  border-top: 1px solid var(--c_light)
  margin: 24px 0


body
  font-family: 'Raleway', sans-serif
  font-display: block
  min-width: 320px
  color: var(--c_text_dark)

  background-color: var(--c_background)

a
  color: var(--c_main)

#page-wrap
  width: 100%
  margin: 0 auto
  position: relative
  overflow: hidden

  >header#header-small
    color: var(--c_main)
    border-bottom: 1px solid var(--c_light)

    div#menu-top-wrap
      background-image: url(img/smol_logo.png)
      background-position: 0px 20px
      background-size: 55px 55px
      background-repeat: no-repeat
      body[theme='dark'] &
        background-image: url(img/smol_logo_dark.png)

      @include respond-to(handhelds)
        background-position: center
        background-size: 24px

      div#menu-top
        position: relative
        padding-left: (100 + $global_padding)
        max-width: $site_width - 100
        margin: 0 auto 0 0
        @include respond-to(handhelds)
          height: 82px
          margin: 0 auto
          padding-left: 18px

        a.home-link
          display: inline-block
          height: 96px
          width: 100px
          position: absolute
          top: 0
          left: 0
          z-index: 10
          @include respond-to(handhelds)
            width: 164px
            height: 82px
            left: 50%
            margin-left: -87px
            background-position: 63px 22px

        nav#header-menu
          a
            color: var(--c_main)
            &.active
              color: var(--c_text_main)
              font-weight: 900

        button#mobile-menu
          margin-top: 4px
          background: url(img/hamburger-g.svg) no-repeat 0 24px

        button#mobile-login
          margin-top: 4px
          background: url(img/enter-g.svg) no-repeat 24px 24px

        button#mobile-user-info
          margin-top: 4px
          background: url(img/exit-g.svg) no-repeat 24px 24px

  >header#header-big
    width: 100%
    font-family: 'Raleway', sans-serif
    background-color: #136a8a
    background-image: linear-gradient(292deg, #136a8a, #267871)

    background-size: cover
    color: white

    div#menu-top
      z-index: 15

      a.home-link
        display: none

    div#info-top
      z-index: 10

    div#header-mask
      z-index: 0
      background-image: url(img/background.jpg)
      background-size: cover
      mix-blend-mode: soft-light
      position: absolute
      top: 0
      left: 0
      height: 100%
      width: 100%

    div#header-mask2
      z-index: 1
      background-image: linear-gradient(292deg, #136a8a, #267871)
      opacity: 0.5
      position: absolute
      top: 0
      left: 0
      height: 100%
      width: 100%

  >header
    position: relative

    div#menu-top-wrap
      width: 100%
      max-width: $site_width
      margin: 0 auto

      div#menu-top
        position: relative
        display: flex
        justify-content: space-between
        margin: 0 auto
        width: 100%
        max-width: $content_width
        box-sizing: border-box
        padding: 0 $global_padding
        height: 96px

        nav#header-menu
          font-size: 14px
          text-transform: uppercase
          font-weight: 700
          letter-spacing: 1.1px
          display: inline-block
          overflow: hidden
          @include respond-to(handhelds)
            display: none

          a
            padding-top: 37px
            margin-right: 25px
            text-decoration: none
            color: white
            display: inline-block
            height: 59px

        button#mobile-menu
          padding: 24px 24px 24px 0
          display: none
          height: 26px
          width: 26px
          background: url(img/hamburger.svg) no-repeat 0 24px
          cursor: pointer
          border-width: 0
          font-family: inherit
          box-sizing: content-box
          color: inherit

          @include respond-to(handhelds)
            display: block


        button#header-login
          padding: 0 0 6px
          font-size: 14px
          text-transform: uppercase
          font-weight: 700
          letter-spacing: 1.1px
          text-align: right
          display: inline-block
          cursor: pointer
          border-width: 0
          font-family: inherit
          box-sizing: content-box
          background: none
          color: inherit

          @include respond-to(handhelds)
            display: none

          a
            text-decoration: none
            color: #fff

        button#mobile-login
          padding: 24px 0 24px 24px
          display: none
          height: 26px
          width: 26px
          background: url(img/enter.svg) no-repeat 24px 24px
          cursor: pointer
          border-width: 0
          font: inherit
          box-sizing: content-box

          @include respond-to(handhelds)
            display: block

        button#header-user-info
          margin: 0
          padding: 0 0 6px 0
          font-size: 14px
          white-space: nowrap
          text-transform: uppercase
          font-weight: 700
          letter-spacing: 1.1px
          text-align: right
          display: inline-block
          cursor: pointer
          border-width: 0
          font-family: inherit
          color: inherit
          box-sizing: content-box
          background: none

          transition: outline 0s linear .1s
          @include respond-to(handhelds)
            display: none

          &:hover
            outline-width: 0

          a
            text-decoration: none
            color: #fff

        button#mobile-user-info
          padding: 24px 0 24px 24px
          display: none
          height: 26px
          width: 26px
          background: url(img/exit.svg) no-repeat 24px 24px
          cursor: pointer
          border-width: 0
          font: inherit
          box-sizing: content-box

          @include respond-to(handhelds)
            display: block

    div#info-top
      position: relative
      clear: both
      background: transparent
      display: flex
      justify-content: flex-start
      margin: 0 auto
      width: 100%
      max-width: $content_width
      box-sizing: border-box
      padding: 0 $global_padding 60px
      @include respond-to(handhelds)
        padding-top: 0
        display: block
        text-align: center

      div#header-logo
        box-sizing: border-box
        margin-top: 10px
        padding: 10px 50px 10px
        text-align: center
        background: url(img/main_logo_glow.png) no-repeat
        background-size: contain
        width: 300px
        height: 300px
        @include respond-to(handhelds)
          width: 50%
          height: auto
          padding: 0
          text-align: center
          max-width: 300px
          max-height: 300px

      div#header-text
        box-sizing: border-box
        display: inline-block
        width: 605px
        padding: 13px 10px 0
        @include respond-to(handhelds)
          width: 100%
          padding: 0 18px
          text-align: center

        h1
          font-weight: 900
          padding: 0
          margin: 0
          font-size: 42px
          line-height: 1.4em
          @include respond-to(mini-handhelds)
            font-size: 34px

        h2
          font-size: 16px
          font-weight: 900
          line-height: 1.5em

        div#header-article
          padding: 16px 0 36px 0
          font-size: 16px
          font-weight: 500
          line-height: 1.5em

        div#header-buttons a:nth-child(2)
          @include respond-except(wide-screens)
            display: none

  >#submenu
    border-bottom: 1px solid var(--c_light)
    @include respond-to(handhelds)
      display: none

    #submenu-wrap
      margin: 0 auto
      width: 100%
      max-width: $content_width

      a
        color: var(--c_main)
        font-size: 14px
        font-weight: 700
        line-height: 1.1
        letter-spacing: 1.1px
        padding: 24px 0 24px $global_padding
        text-decoration: none
        text-transform: uppercase
        display: inline-block

        &.active
          font-weight: 900
          line-height: 1
          color: var(--c_text_main)

  >#content-wrap
    margin: 30px auto
    max-width: $content_width
    width: 100%
    padding: 0 18px
    box-sizing: border-box
    @include respond-except(handhelds)
      margin-top: 60px

  >footer
    clear: both
    margin: 142px auto 0
    border-top: 1px solid var(--c_light)
    text-align: center
    padding: 40px 18px
    @include respond-to(handhelds)
      margin-top: 78px
      padding-top: 24px

    div#dark-theme-button
      height: 30px
      width: 30px
      background-size: contain
      background-repeat: no-repeat
      background-position: center
      background-image: url(img/dark_theme_button_dark.png)
      body[theme='dark'] &
        background-image: url(img/dark_theme_button.png)

    div#footer-logo
      display: inline-block
      margin: 12px 24px
      width: 206px
      height: 59px
      background-image: url(img/footer_logo.png)
      background-repeat: no-repeat
      background-position: center
      background-size: contain
      vertical-align: middle
      body[theme='dark'] &
        background-image: url(img/footer_logo_dark.png)

    ul
      display: inline-flex
      flex-direction: row
      align-items: center
      margin: 12px 24px
      padding: 0
      @include respond-to(handhelds)
        flex-direction: column
        display: flex
    
    

      li
        display: inline-block
        margin: 0
        padding: 12px 10px
        font-size: 16px
        font-weight: 500
        line-height: 1.5em

        a
          color: var(--c_main)
          text-decoration: none

//////////////////////////////

article
  position: relative

#quill-text
  min-height: 100px

article, #quill-text
  width: 100%
  color: var(--c_text_dark)
  font-family: 'Raleway', serif
  font-size: 16px
  font-weight: 500

  h1
    @include respond-to(handhelds)
      text-align: center
    font-size: 30px
    line-height: 1.4
    font-weight: 700
    margin: 36px 0 24px 0
    color: var(--c_text_dark)
    text-align: left

  div.subheader
    @include respond-to(handhelds)
      text-align: center
    margin: 24px 0 36px 0
    font-size: 16px
    line-height: 18px
    font-weight: 900
    color: var(--c_text_dark)
    text-align: left

  h2
    @include respond-to(handhelds)
      text-align: center
    margin: 48px 0 24px 0
    font-size: 24px
    line-height: 30px
    font-weight: 800
    color: var(--c_text_dark)
    text-align: left

  h3
    margin: 42px 0 12px
    font-size: 16px
    line-height: 18px
    font-weight: 900
    text-align: left

  p, ul, ol
    margin: 24px 0
    font-size: 16px
    line-height: 24px
    letter-spacing: 0.2px

  a
    color: var(--c_main)
    font-style: inherit
    font-size: inherit
    font-weight: inherit

  aside
    font-size: 14px
    font-weight: 400

  pre, code
    font-size: 14px
    font-family: monospace
    background-color: #f0f0f0
    color: var(--c_text_dark)

  strong
    font-weight: 800

section
  width: 100%
  position: relative
  margin: 36px 0

  >h1
    margin: 12px
    text-align: center
    font-size: 30px
    font-weight: 700
    line-height: 1.4
    color: var(--c_text_main)

    &.font-big
      @include respond-except(handhelds)
        font-size: 42px

  p.subheader
    padding: 0
    margin: 16px 0 51px
    text-align: center

    a
      text-decoration: none
      font-size: 16px
      font-weight: 600
      line-height: 1.1
      color: var(--c_text_grey)

  >h2
    font-size: 16px
    font-weight: 700
    line-height: 1.1
    text-align: center
    color: var(--c_text_dark)
    margin: 24px 0 36px

section, article
  a.header-side-link, a.header-top-link, a.header-side-link-2, a.header-back-link
    text-align: center
    margin: 4px 0 30px 0
    display: block
    font-size: 14px
    font-weight: 700
    line-height: 1.1
    letter-spacing: 1.1px
    color: var(--c_main)
    text-transform: uppercase
    text-decoration: none
    cursor: pointer
    @include respond-except(handhelds)
      text-align: right

  @include respond-except(handhelds)
    a.header-side-link
      position: absolute
      top: 12px
      right: 6px

    a.header-side-link-2
      position: absolute
      top: 64px
      right: 6px

    a.header-top-link
      margin: 0 0 -36px 0

    a.header-back-link
      position: absolute
      top: 12px
      left: 6px

      &:before
        content: ""
        display: inline-block
        background: url('img/back.svg') right bottom no-repeat
        background-size: 13px 11px
        width: 20px
        height: 13px
        transform: scaleX(-1)

///////////////////////

.middle-helper
  line-height: 100%
  height: 100%
  width: 0
  vertical-align: middle
  display: inline-block
  visibility: hidden

.dashed-border
  border-radius: 4px
  border: dashed 3px
  border-image-source: url("img/border_t.png")
  border-image-slice: 3
  border-image-repeat: round

a.right-arrow
  padding-right: 23px
  background: url('img/watch_on_site.svg') no-repeat right bottom
  background-size: 17px 15px
