@import "../util/vars"

div.input-radio-group.small-height
  padding: 6px 12px 12px

  div.label, div.label-collapsed
    font-size: 12px
    font-weight: 500
    line-height: 1.33
    color: var(--c_text_grey)

  div.label-collapsed
    display: none
    background: url('img/input-select-arrow.svg') no-repeat right center
    background-size: auto 42%

  &.collapsed
    padding: 6px 12px
    cursor: pointer
    div.label-collapsed
      display: block
    div.label, div.input-radio
      display: none

  &:focus
    outline: none

  div.input-radio
    padding: 6px 0

    label
      font-size: 12px
      font-weight: 500
      line-height: 1.33
      color: var(--c_text_grey)
      cursor: pointer

      &:before
        content: ""
        color: var(--c_main)
        display: inline-block
        width: 10px
        height: 10px
        border: solid 1px var(--c_main)
        border-radius: 6px
        vertical-align: text-top
        text-align: center
        margin-right: 6px
        font-size: 30px
        line-height: 10px
        text-indent: -0.4px

    input
      width: 0
      height: 0
      opacity: 0

    input:checked+label
      color: var(--c_text_main)

      &:before
        content: "•"