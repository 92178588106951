// SIZES
$site_width: 1278px
$content_width: 1078px
$global_padding: 18px

$s_micro: 450px
$s_mini: 600px
$s_small: 900px
$s_large: 1100px


body
  --c_main: #48a1a5
  --c_main_trans_half: #48a1a688
  --c_main_trans: #48a1a6CC
  --c_dark: #036caa
  --c_light: #dddfe0
  --c_light_trans_half: #dddfe088
  --c_red_light: #dd5656
  --c_red_main: #e24d32
  --c_red_dark: #d23c3c
  --c_red_dark_trans: #d23c3cCC
  --c_yellow: #cfb63e
  --c_yellow_trans: #cfb63eCC
  --c_green_rich: #3ecf3e
  --c_green_rich_trans: #3ecf3eCC
  --c_text_dark: #333333
  --c_text_main: #3e4757
  --c_text_grey: #9299a2
  --c_text_light: #F6F7F7
  --c_background: #FFFFFF

  --c_input: #fff
  --c_input_text: #3e4757


body[theme='dark']
  --c_main: #48a1a6
  --c_main_trans_half: #48a1a688
  --c_main_trans: #48a1a6CC
  --c_dark: #036caa
  --c_light: #909090
  --c_light_trans_half: #dddfe088
  --c_red_light: #dd5656
  --c_red_main: #e24d32
  --c_red_dark: #d23c3c
  --c_red_dark_trans: #d23c3cCC
  --c_yellow: #cfb63e
  --c_yellow_trans: #cfb63eCC
  --c_green_rich: #3ecf3e
  --c_green_rich_trans: #3ecf3eCC
  --c_text_dark: #CCCCCC
  --c_text_main: #EDEFF2
  --c_text_grey: #F6F7F7
  --c_text_light: #F6F7F7
  --c_background: #191a1c

  --c_input: #2b2a32
  --c_input_text: #ffffff


@mixin respond-to($media)
  @if $media == mini-handhelds
    @media only screen and (max-width: $s_micro)
      @content
  @if $media == medium-handhelds
    @media only screen and (min-width: $s_micro + 1) and (max-width: $s_mini)
      @content
  @if $media == not-big-handhelds
    @media only screen and (max-width: $s_mini)
      @content
  @if $media == big-handhelds
    @media only screen and (min-width: $s_mini + 1) and (max-width: $s_small)
      @content
  @else if $media == handhelds
    @media only screen and (max-width: $s_small)
      @content
  @else if $media == medium-screens
    @media only screen and (min-width: $s_small + 1) and (max-width: $s_large - 1)
      @content
  @else if $media == wide-screens
    @media only screen and (min-width: $s_large)
      @content


@mixin respond-except($media)
  @if $media == mini-handhelds
    @media only screen and (min-width: $s_micro + 1)
      @content
  @else if $media == not-big-handhelds
    @media only screen and (min-width: $s_mini + 1)
      @content
  @else if $media == handhelds
    @media only screen and (min-width: $s_small + 1)
      @content
  @else if $media == medium-screens
    @media only screen and (max-width: $s_small)
      @content
    @media only screen and (min-width: $s_large)
      @content
  @else if $media == wide-screens
    @media only screen and (max-width: $s_large - 1)
      @content
